

export default {
  name: 'MoleculeSpaceDetailsOnSpacePage',
  props: {
    type: {
      required: true,
      type: String,
      default: "",
    },
    space: {
      required: true,
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  computed: {
    surfaceMultiplier() {
      return this.$store.state?.project?.surfaceMultiplier || 1
    },
    unitOfMeasureString() {
      return this.$store.state.base.meta.generalConfig.unitOfMeasure === 1 ? 'sqft' : 'sqm'
    },
    surfaceName() {
      return this.$store.state?.project?.surfaceName || 1
    },
    showGeneratedNumberOfPeople() {
      const commonFeatures =
        this.$store?.state?.base?.meta?.project?.sections?.find(
          (section) => section.type === 'Building'
        ) || {}
      if ('generatedNumberOfPeople' in commonFeatures) {
        return commonFeatures.generatedNumberOfPeople
      } else {
        return true
      }
    },
  }
}
