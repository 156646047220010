

export default {
  name: 'MoleculeSpaceDetailsTotalResultCart',
  props: {
    type: {
      required: true,
      type: String,
      default: "",
    }
  },
  computed: {
    surfaceMultiplier() {
      return this.$store.state?.project?.surfaceMultiplier || 1
    },
    unitOfMeasure() {
      return this.$store.state.base.meta.generalConfig.unitOfMeasure
    },
    unitOfMeasureString() {
      return this.unitOfMeasure === 1 ? 'sqft' : 'sqm'
    },
    surfaceName() {
      return this.$store.state?.project?.surfaceName || 1
    },
    showGeneratedNumberOfPeople() {
      const commonFeatures =
        this.$store?.state?.base?.meta?.project?.sections?.find(
          (section) => section.type === 'Building'
        ) || {}
      if ('generatedNumberOfPeople' in commonFeatures) {
        return commonFeatures.generatedNumberOfPeople
      } else {
        return true
      }
    },
    requestedSpaces() {
      return this.$store.state.requestOffer.spaces
    },
    totalSpace() {
      let total = 0
      this.requestedSpaces.forEach((s) => {
        total += s.sqm
      })
      return total
    },
    totalPeople() {
      if (this.unitOfMeasure === 1) {
        const unitSQM = this.totalSpace / 10.763910417
        return Math.floor(unitSQM / 8)
      }
      return Math.floor(this.totalSpace / 8)
    },
    totalNla() {
      let totalNla = 0
      this.requestedSpaces.forEach((s) => {
        totalNla += s.nla
      })
      return totalNla
    },
    totalLfa() {
      let totalLfa = 0
      this.requestedSpaces.forEach((s) => {
        totalLfa += s.lfa
      })
      return totalLfa
    },
  }
}
