
import baseConstants from '~/store/base/-constants'
import projectConstants from '~/store/project/-constants'
import {isMobile, isTablet} from "~/helpers/mobile/DeviceType";
import requestOfferConstants from "~/store/requestOffer/-constants";
export default {
  name: 'MoleculeSidebarHeader',
  data() {
    return {
      buildingOptions: [],
      floorOptions: [],
      surfaceOptions: [],
      selectedMeasurement: 1
    }
  },
  computed: {
    includeLeasedSpacesInResultsList() {
      return this.$store.state.base.includeLeasedSpacesInResultsList
    },
    activeProject() {
      return this.$store.state.project.activeProject
    },
    buildings() {
      return this.$store.state.project.project.buildings
    },
    floors() {
      return this.$store.state.project.project.floors
    },
    spaces() {
      return this.$store.state.project.project.spaces
    },
    selectedBuilding() {
      return this.$store.state.project?.filters?.building
    },
    selectedFloor() {
      return this.$store.state.project?.filters?.floor
    },
    selectedFloorObject() {
      if (this.selectedBuilding === "") return "";
      return this.floors.find((f) => {
        return (
          `${f.no}` === `${this.selectedFloor}` &&
          `${f.building_id}` === `${this.selectedBuilding}`
        );
      });
    },
    availableFloorsForSelect() {
      const filteredFloors = this.floors.filter((f) => {
        return f.building_id === this.selectedBuilding
      })
      const floors = []
      const floorOptions = []
      filteredFloors.forEach((f, i) => {
        const fNumber = f.name.split(' ')[1]
        if (floors.indexOf(f.no) === -1) {
          floorOptions.push({
            text: fNumber ? `${this.$t("floor")} ${fNumber}` : `${this.$t(f.name.toLowerCase())}`,
            value: f.no
          })
          floors.push(f.no)
        }
      })
      floorOptions.sort(function (a, b) {
        if (a.value < b.value) {
          return -1
        }
        if (a.value > b.value) {
          return 1
        }
        return 0
      })
      floorOptions.unshift({ text: this.$t('any'), value: "" })
      return floorOptions
    },
    availableSurfacesForSelect() {
      let filteredSpaces = []
      if (this.selectedFloorObject === "") {
        filteredSpaces = this.spaces
      } else {
        filteredSpaces = this.spaces.filter((s) => {
          return s.floor_id === this.selectedFloorObject?.id
        })
      }
      const surfaces = []
      const surfaceOptions = []
      filteredSpaces.filter((s) => this.visibleLeasedSpaces && !this.includeLeasedSpacesInResultsList ? s.available : true).forEach((s, i) => {
        if (surfaces.indexOf(s.sqm_range) === -1) {
          surfaceOptions.push({
            text: `${s.sqm_range} ㎡`,
            value: s.sqm_range
          })
          surfaces.push(s.sqm_range)
        }
      })
      surfaceOptions.sort(function (a, b) {
        const first = parseInt(a.value.split('-')[0])
        const second = parseInt(b.value.split('-')[0])
        return second < first ? 1 : second > first ? -1 : 0
      })
      surfaceOptions.unshift({ text: this.$t('any'), value: "" })
      return surfaceOptions
    },
    measurements() {
      return this.$store.state?.base?.meta?.generalConfig.measurements || null
    },
    measurementsEnabled() {
      return this.measurements?.isEnabled || false
    },
    measurementsItems() {
      if (this.measurementsEnabled && this.measurements.types && this.measurements.types.length > 0)
        return this.measurements.types
      return []
    },
    surfaceMultiplier() {
      return this.$store.state?.project?.surfaceMultiplier || 1
    },
    surfaceName() {
      return this.$store.state?.project?.surfaceName || 1
    },
    unitOfMeasure() {
      return this.$store.state.base.meta.generalConfig.unitOfMeasure
    },
    unitOfMeasureString() {
      return this.$store.state.base.meta.generalConfig.unitOfMeasure === 1 ? 'sqft' : this.$t("sqm")
    },
    filters() {
      return this.$store.state.project.filters
    },
    engine3d() {
      return this.$engine3d || window.engine3d
    },
    projectFeatures() {
      return this.$store.getters?.featuresForProject(this.activeProject);
    },
    visibleLeasedSpaces() {
      return this.projectFeatures?.visibleLeasedSpaces;
    }
  },
  watch: {
    activeProject: function () {
      this.initFilterOptions()
    },
    buildings: function () {
      this.initFilterOptions()
    }
  },
  mounted() {
    this.initFilterOptions()
  },
  methods: {
    handleShowLeasedSpacesToggle(isChecked) {
      this.$store.dispatch(baseConstants.withNamespace(baseConstants.action.TOGGLE_SHOW_LEASED_SPACES), isChecked);
    },
    isRadioChecked(measurement) {
      return this.surfaceMultiplier === measurement.multiplier
    },
    initFilterOptions() {
      this.setBuildingOptions()
      this.setFloorOptions()
      this.setSurfaceOptions()
    },
    setBuildingOptions() {
      const buildingOptions = [{ text: this.$t('any'), value: "" }]
      this.buildings.forEach((b, i) => {
        buildingOptions.push({ text: b.name, value: b.id })
      })
      buildingOptions.sort(function (a, b) {
        if (a.value < b.value) {
          return -1
        }
        if (a.value > b.value) {
          return 1
        }
        return 0
      })
      this.buildingOptions = buildingOptions
    },
    setFloorOptions() {
      const floors = []
      const floorOptions = [{ text: this.$t('any'), value: "" }]
      this.floors.forEach((f, i) => {
        const fNumber = f.name.split(' ')[1]
        if (floors.indexOf(f.no) === -1) {
          floorOptions.push({
            text: fNumber ? `${this.$t("floor")} ${fNumber}` : `${this.$t(f.name.toLowerCase())}`,
            value: f.no,
          });
          floors.push(f.no);
        }
      })
      floorOptions.sort(function (a, b) {
        if (a.value < b.value) {
          return -1
        }
        if (a.value > b.value) {
          return 1
        }
        return 0
      })
      this.floorOptions = floorOptions
    },
    setSurfaceOptions() {
      const surfaces = []
      const surfaceOptions = []
      this.spaces.filter((s) => this.visibleLeasedSpaces && !this.includeLeasedSpacesInResultsList ? s.available : true).forEach((s, i) => {
        if (surfaces.indexOf(s.sqm_range) === -1) {
          surfaceOptions.push({
            text: `${s.sqm_range} ㎡`,
            value: s.sqm_range
          })
          surfaces.push(s.sqm_range)
        }
      })
      surfaceOptions.sort(function (a, b) {
        const first = parseInt(a.value.split('-')[0])
        const second = parseInt(b.value.split('-')[0])
        return second < first ? 1 : second > first ? -1 : 0
      })
      surfaceOptions.unshift({ text: this.$t('any'), value: "" })
      this.surfaceOptions = surfaceOptions;
    },
    handleFilterChange(e, type) {
      this.$store.dispatch(
        projectConstants.withNamespace(projectConstants.action.CHANGE_FILTER_STATE),
        {
          value: e.value,
          type
        }
      )

      const buildingList = document.querySelector('.building-list')
      if (buildingList) {
        buildingList.querySelectorAll('.trigger-click-view-space').forEach((space) => {
          space.classList.remove('activated')
        })
        buildingList.querySelectorAll('.row-floor').forEach((parentSpace) => {
          parentSpace.classList.remove('activation')
        })
        const manager = this.engine3d.getClientManager();
        if (manager) {
          manager.hideHighlight()
        }
      }
    },
    handleSelectMeasurement(value) {
      this.$store.dispatch(
        projectConstants.withNamespace(projectConstants.action.UPDATE_SURFACE_MULTIPLIER),
        Number(value)
      )
    },
    handleSelectMeasurementLabel(dataText) {
      this.$store.dispatch(
        projectConstants.withNamespace(projectConstants.action.UPDATE_SURFACE_NAME),
        dataText
      )
    },
    hideAvailableSpaces() {
      this.$store.dispatch(
        baseConstants.withNamespace(baseConstants.action.CHANGE_MOBILE_AVAILABILITY_MENU),
        false
      )
    }
  }
}
