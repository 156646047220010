
export default {
  name: 'MoleculeVideoSlider3DTour',
  props: {
    sliderData: {
      required: true,
      type: Object || Array,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {
      instance: null
    }
  },
  mounted() {
    this.initCarousel()
  },
  beforeUpdate() {
    this.destroyCarousel()
  },
  updated() {
    this.initCarousel()
  },
  methods: {
    initCarousel() {
      const carouselOpts = {
        items: 1,
        nav: true,
        dots: false,
        margin: 20
      }
      this.instance = window.$(this.$refs.carousel).owlCarousel(carouselOpts)
      this.$emit('initialized', true)
    },
    destroyCarousel() {
      if (!this.instance) return
      this.instance.trigger('destroy.owl.carousel')
      this.instance.off('change.owl.carousel')
      this.instance = null
      this.$emit('initialized', false)
    },
    refreshCarousel() {
      this.instance.trigger('refresh.owl.carousel')
    }
  }
}
