
import guidedTourConstants from '~/store/guidedTour/-constants'

export default {
  name: 'MoleculeGuidedTourTimeline',
  props: {
    timelineLocation: {
      required: true,
      type: Object,
      default: function () {
        return {}
      }
    },
    index: {
      required: true,
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      activeIndex: -1
    }
  },
  computed: {
    locale() {
      return this.$i18n?.locale || 'en'
    },
    timelineLocations() {
      return this.$store.getters['guidedTour/timelineLocations']
    },
    currentLocation() {
      return this.$store.state.guidedTour.currentLocation
    }
  },
  watch: {
    currentLocation: function (currentLocation, oldLocation) {
      if (currentLocation !== oldLocation) {
        const foundIndex = this.timelineLocations.findIndex((l) => l.uuid === currentLocation)
        if (foundIndex !== -1) this.activeIndex = foundIndex
      }
    }
  },
  methods: {
    async handleTimeLineNavigation(location) {
      await this.$store.dispatch(guidedTourConstants.withNamespace('resetTourForCustomNavigation'))
      setTimeout(() => {
        this.$store.dispatch(guidedTourConstants.withNamespace('handleLocationFade'), location)
      }, 500)
    }
  }
}
