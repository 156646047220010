
export default {
  name: 'MoleculePinInfoContent',
  props: {
    pinInfo: {
      required: false,
      type: Object,
      default: function () {
        return {
          highlightAnchor: '',
          pinTitle: '',
          pinDescription: ''
        }
      }
    }
  }
}
