

export default {
  name: 'MoleculeSidebarResultSpaceDetails',
  props: {
    type: {
      required: true,
      type: String,
      default: "",
    },
    space: {
      required: true,
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  computed: {
    surfaceMultiplier() {
      return this.$store.state?.project?.surfaceMultiplier || 1
    },
    unitOfMeasureString() {
      return this.$store.state.base.meta.generalConfig.unitOfMeasure === 1 ? 'sqft' : 'sqm'
    },
    surfaceName() {
      return this.$store.state?.project?.surfaceName || 1
    },
    showGeneratedNumberOfPeople() {
      const commonFeatures =
        this.$store?.state?.base?.meta?.project?.sections?.find(
          (section) => section.type === 'Building'
        ) || {}
      if ('generatedNumberOfPeople' in commonFeatures) {
        return commonFeatures.generatedNumberOfPeople
      } else {
        return true
      }
    },
    measurements() {
      return this.$store.state?.base?.meta?.generalConfig.measurements || null
    },
    measurementsEnabled() {
      return this.measurements?.isEnabled || false
    },
    measurementsItems() {
      if (this.measurementsEnabled && this.measurements.types && this.measurements.types.length > 0)
        return this.measurements.types
      return []
    },
  }
}
