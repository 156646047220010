import { render, staticRenderFns } from "./MoleculeSpaceDetailsTotalResultCart.vue?vue&type=template&id=aa6e04b2&"
import script from "./MoleculeSpaceDetailsTotalResultCart.vue?vue&type=script&lang=js&"
export * from "./MoleculeSpaceDetailsTotalResultCart.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/lang/MoleculeSidebarResultSpaceDetails.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fcomponents%2Fmolecules%2Fproject%2Fbuilding%2FMoleculeSpaceDetailsTotalResultCart.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsCommonAtomIcon: require('/app/components/atoms/common/AtomIcon.vue').default})
